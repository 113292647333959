@import 'colors';

//reset css

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  font-size: 14px;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  font-family: Helvetica, Arial, sans-serif !important;
  font-size: 14px;
  line-height: 1.42857143;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  color: #fc1e8e;
  border: 1px solid #ccc;
  background: #f1f1f1;
  border-radius: 3px;
  padding: 0 5px;
}
pre {
  border: 1px solid #ccc;
  background: #f1f1f1;
  border-radius: 3px;
  padding: 5px 10px;
  line-height: 1.2;

  pre {
    padding: 0;
    border: none;
  }
  code {
    color: #383737;
    padding: 0;
    border: none;
    background: transparent;
    display: block;
  }
}

h1 {
  font-size: 30px;
  font-weight: bold;
}
h2 {
  font-size: 27px;
  font-weight: bold;
}
h3 {
  font-size: 24px;
  font-weight: bold;
}
h4 {
  font-size: 21px;
  font-weight: bold;
}
h5 {
  font-size: 18px;
  font-weight: bold;
}
h6 {
  font-size: 15px;
  font-weight: bold;
}

ol,
ul {
  //list-style: none;
  padding-left: 18px;
}

blockquote {
  padding: 3px 10px;
  margin: 3px 0;
  border-left: 3px solid #ccc;
}

blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

strong {
  font-weight: bold;
}
em {
  font-style: italic;
}

a {
  color: $blue-lochmara;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

//=========================================================
//=========================================================

//Common styles

button:focus {
  outline: none;
}

.not-found {
  text-align: center;
  margin: 50px;
  font-size: 18px;
}

.modal-backdrop {
  opacity: 0.5;
}

.hidden {
  display: none !important;
}

.shown {
  display: flex !important;
}

.error {
  color: red;
}

.bold {
  font-weight: bold;
}

.cntr {
  text-align: center;
}

.pt10 {
  padding-top: 10px;
}

.pt15 {
  padding-top: 15px;
}

.pb10 {
  padding-bottom: 10px;
}

input,
textarea {
  &::placeholder {
    color: $grey-nobel;
  }
}

div,
ul,
textarea {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #a4a4a4;
    }
  }
}
