$green: #C5DBEB ;
$blue: #7FAFD3 ;
$red: #3868A0 ;
$yellow: #FFC100 ;
$white: #eee;

.loader-box {
    display: inline-block;
    position: relative;

    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }

    .circular {
        animation: loader-rotate 2s linear infinite;
        height: 100%;
        transform-origin: center center;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    .path {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation: loader-dash 1.5s ease-in-out infinite, loader-color 6s ease-in-out infinite;
        stroke-linecap: round;
    }
}


@keyframes loader-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader-dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@keyframes loader-color {
    100%,
    0% {
        stroke: $red;
    }
    40% {
        stroke: $blue;
    }
    66% {
        stroke: $green;
    }
    80%,
    90% {
        stroke: $yellow;
    }
}
