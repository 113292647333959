.sidebar-empty-search-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 100px);
  text-align: center;
  padding: 10px;

  h3 {
    font-size: 18px;
    font-weight: 400;
    color: #d9c6dd;
    margin: 0 0 25px;
  }

  .not-found-img {
    width: 160px;
  }
}
