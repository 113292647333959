@import '../../../../colors';

.comment {
  display: flex;
  flex-direction: row;
  padding: 0 15px 1px 0;
  position: relative;

  &:hover {
    background-color: $white-smoke;

    .menu {
      opacity: 1;
      z-index: 2;
    }
  }

  &.editingView {
    padding-top: 5px;
    background-color: $white-smoke;
  }

  :global(.user-mention) {
    color: $blue-lochmara;
  }
}

.labelEdited {
  color: $grey-nobel;
  font-size: 12px;
}

.menu {
  position: absolute;
  top: -10px;
  right: 7px;
  opacity: 0;
  z-index: -1;
}

.btnsBox {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.btn {
  min-width: 40px;
  min-height: 27px;
  background: #fff;
  color: #999999;
  border: 1px solid $grey-gainsboro;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  margin-left: -1px;
  position: relative;
  z-index: 1;

  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    color: $blue-lochmara;
    border-color: $blue-lochmara;
    z-index: 2;
  }
}

.reactionsList {
  padding: 2px 0;
}

.allReactionsBox {
  position: fixed;
  z-index: 5;
  margin-left: -220px;
}
