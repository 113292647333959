@import '../../../colors';

.openModalBtn {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background: $btn-primary;
  font-size: 13px;
  padding: 3px 8px;
  border-radius: 5px;
  margin-right: 30px;
  border: none;
  outline: none;

  svg {
    margin-right: 6px;
  }

  &:hover {
    background: $btn-primary--hover;
  }
}
