.CardPopup {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 56px;
  padding: 6px 20px;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  color: #fff;
  background: #7FAFD3;
  font-size: 14px;
  z-index: 20;
  opacity: 0;
  transition: all .3s ease;

  &.animate {
    opacity: 1;
    transition: all .6s ease;
  }
}
