@import '../../../colors';

.box {
  background: #fff;
  padding: 25px 20px 35px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
}

.content {
  max-width: 450px;
  margin: 0 auto;
}

.title {
  font-size: 30px;
  text-align: center;
  margin: 0 0 35px;
  color: $black-ebony;
}

.formBox {
  max-width: 330px;
  margin: 0 auto;
}

.passwordInfo {
  position: absolute;
  top: -4px;
  left: 100%;
  padding-left: 12px;
  font-size: 13px;
  line-height: 1.2;
  width: 175px;
  color: $grey-dim;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s;
}

.submitBtnBox {
  padding-top: 25px;
}

.btnIcon {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 10px;
  border-color: transparent transparent transparent #000;
  margin: 0 0 3px 5px;
}
