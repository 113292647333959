.imageBox {
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 12px;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.imgMain {
  flex-grow: 1;
}

.imgAll {
  flex-shrink: 0;
  margin-left: 2px;
  width: 80px;
  height: 100%;
}

.imgRest {
  margin-bottom: 2px;
  height: 100%;
}

.imgView {
  width: 195px;
  height: 135px;
  display: flex;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: opacity 0.15s;

    &:hover {
      opacity: 0.85;
    }
  }

  &.two {
    .imgAll {
      width: 50%;
    }
  }
  &.three {
    .imgRest {
      height: 50%;
    }
  }
  &.more {
    .imgRest {
      height: 33%;
    }
  }
}

.labelMoreImg {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #fff;
  font-size: 14px;
  line-height: 1;
  padding: 6px 12px;
  width: 80px;
  height: 42px;
  background: rgba(44, 46, 51, 0.8);
}

.modalBox {
  padding: 10px 15px 15px;

  .imagesModalHeader {
    padding: 0 0 15px;
  }
}

.slider {
  :global(.slick-arrow) {
    width: 65px;
    height: 65px;
    background: rgba(216, 216, 216, 0.08);
    border-radius: 50%;
    z-index: 2;

    &:before {
      content: '';
      display: block;
      background: url('../../../../assets/img/arrow-left.svg') no-repeat;
      background-size: contain;
      width: 17px;
      height: 25px;
      margin: auto;
      opacity: 1;
    }

    &:hover {
      background: rgba(216, 216, 216, 0.2);
    }
  }

  :global(.slick-prev) {
    left: -90px;
  }

  :global(.slick-next) {
    right: -90px;

    &:before {
      transform: rotate(180deg);
    }
  }
}

.slide {
  font-size: 14px;
  line-height: 1.3;

  img {
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }

  p {
    margin-top: 10px;
    white-space: pre-wrap;
  }
}
