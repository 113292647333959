.wrapper {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 100;
}

.notification {
  display: block;
  max-width: 280px;
  margin: 0 auto;
  position: relative;
}

.btnGoUp {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  background: #729ac8;
  color: #fff;
  font-size: 11px;
  line-height: 1.4;
  border: none;
  padding: 2px 20px 4px;
}

.btnGoUp img {
  margin-right: 5px;
}

.btnClose {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  background: url('../../../assets/img/x-white.svg') no-repeat center center;
  width: 21px;
  height: 100%;
  border: none;
  padding: 0;
}
