.sidebar-group-item {
  .group-settings-box {
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    .group-settings-box {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.group-settings-box {
  display: flex;
  align-items: center;
  padding-left: 10px;
  position: relative;
}

.group-settings-btn {
  border: none;
  display: inline-block;
  vertical-align: top;
  padding: 0;
  background: url('../../../../assets/img/settingsIcon.svg') no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  outline: none;
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

.group-settings-menu {
  background: #fff;
  position: absolute;
  right: -7px;
  top: 100%;
  margin-top: 10px;
  border-radius: 6px;
  width: 120px;
  z-index: 2;
  box-shadow: 0 2px 11px rgba(0, 0, 0, 0.2);
  cursor: default;

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    right: 7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
  }
}

.group-settings-list {
  list-style: none;
  padding: 6px 10px 7px;

  & > li {
    & + li {
      margin-top: 5px;
    }
  }

  button {
    border: none;
    font-size: 13px;
    padding: 0;
    background: transparent;
    color: #34383d;

    &:hover {
      color: #d64658;
    }

    &:disabled {
      color: #aaa;
    }
  }
}
