@import '../../../../colors';

.manage-group-modal-overlay {
  background: transparent;
  max-width: 1300px;
  margin: 0 auto;
}

.manage-group-modal-content {
  position: absolute;
  background: #fff;
  top: 0;
  right: 0;
  width: calc(100% - 280px);
  border-left: 1px solid #e8e8e8;
  height: 100vh;
  z-index: 5;
}

.ManageGroup {
  color: #000;

  .header-box {
    margin: 15px 30px 0;
    position: relative;

    .close-btn {
      display: block;
      width: 75px;
      height: 26px;
      text-align: center;
      color: #fff;
      background: $btn-primary;
      font-size: 14px;
      border-radius: 5px;
      cursor: pointer;
      border: none;
      outline: none;
      position: absolute;
      top: 0;
      left: 0;

      &:hover {
        background: $btn-primary--hover;
      }
    }

    h2 {
      color: #000;
      margin: 0;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
  }

  .group-info {
    padding: 25px 30px;
  }

  .group-name {
    font-size: 18px;
    font-weight: bold;
    word-break: break-word;
    text-align: center;
  }

  .invite-block {
    padding: 10px 30px 15px;

    input {
      background: #f6f6f6;
      padding: 5px 40px 5px 10px;
      border-radius: 8px;
      border: none;
      width: 100%;
      height: 28px;
      outline: none;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
    }

    .close-img {
      position: absolute;
      top: 4px;
      width: 20px;
      right: 10px;
      cursor: pointer;
      line-height: 1;

      img {
        display: block;
        width: 100%;
      }
    }

    .manage-group-search {
      position: relative;
      height: 36px;
    }
  }

  .members-counter {
    font-size: 14px;
    text-transform: uppercase;
    color: #999;
    margin-bottom: 15px;
  }

  .invite-member-icon {
    height: 36px;
    color: rgb(56, 104, 160);
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon-plus {
      background: rgb(197, 219, 235);
      display: inline-block;
      width: 28px;
      height: 28px;
      font-size: 28px;
      border-radius: 50%;
      text-align: center;
      line-height: 22px;
      margin-right: 10px;

      &:hover {
        background: #b9cfdf;
      }
    }

    & > span {
      font-weight: bold;
    }
  }

  .members-list {
    overflow-y: auto;
    max-height: calc(100vh - 325px);
    padding-bottom: 10px;

    .group-member {
      display: flex;
      align-items: center;
      position: relative;
      margin: 0 15px;
      padding: 12px 15px;
      border-radius: 12px;

      &:hover {
        background: rgba(197, 219, 235, 0.25);
      }
    }

    .invitation-wrapper {
      flex-grow: 1;
      //display: flex;
      justify-content: flex-end;
      display: none;
    }

    .close-img {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      width: 22px;
      height: 22px;
      cursor: pointer;

      img {
        width: 100%;
      }
    }

    .group-member-photo {
      width: 28px;
      height: 28px;
      flex: none;
      margin-right: 13px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .group-member-offline {
      color: rgb(56, 104, 160);
    }

    .invite-btn {
      //display: none;
      color: #fff;
      background: $btn-primary;
      width: 76px;
      height: 25px;
      //position: absolute;
      //right: 20px;
      //top: 20px;
      border: none;
      outline: none;
      border-radius: 5px;

      &:hover {
        background: $btn-primary--hover;
      }
    }

    .group-member:hover .invitation-wrapper {
      display: flex;
    }

    .pending-invitation {
      color: #000;
      position: absolute;
      right: 20px;
      top: 22px;

      span {
        color: #999;
      }
    }
  }
}
