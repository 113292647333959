@import '../../../colors';

.openModalBtn {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  background: transparent;
  font-size: 12px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;

  svg {
    margin-right: 4px;
  }

  &:hover {
    color: $blue-lochmara;
  }
}
