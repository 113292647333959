@import '../../../../colors';

.field {
  min-height: 28px;
  max-height: 200px;
  overflow-y: auto;
  font-size: 14px;
  background: #fff;
  border: 1px solid $grey-silver;
  border-radius: 5px;
  padding: 3px 10px;
}

.buttonsBox {
  padding: 6px 0;
}

.closeBtn {
  background: $grey-very-light;

  &:hover {
    background: $grey-silver;
  }

  &:disabled {
    background: $grey-very-light;
  }
}

.sendBtn {
  color: #fff;
  background: $btn-primary;

  &:hover {
    background: $btn-primary--hover;
  }

  &:disabled {
    background: $btn-primary--disabled;
  }
}

.closeBtn,
.sendBtn {
  min-width: 105px;
  border: none;
  margin-right: 10px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2;
  padding: 5px 10px;
  border-radius: 2px;
}

.additionalInfo {
  position: relative;

  & > ul {
    position: absolute;
    top: 3px;
    right: 0;
  }

  .errorBox {
    position: absolute;
    top: 19px;
    right: 0;
    font-size: 12px;
  }
}
