.tagsWrapper {
  position: relative;

  .addTagBtn {
    position: absolute;
    top: 4px;
    right: 0;
    z-index: -1;
  }
}

.tagsBox {
  margin-top: 7px;
  border: none;
  padding-right: 35px;
  cursor: pointer;

  input {
    cursor: pointer;
  }
}
