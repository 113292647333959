$orange: #ffc102;
$yellow-selective: #ffb800;
$yellow-kournikova: #f6cd4e;
$blue-lochmara: #3768a0;
$blue-pattens: #c5daeb;
$blue-solitude: #e7eff6;
$blue-alice: #f8faff;
$blue-alice-darken: #f4f9fe;
$white-smoke: #f5f5f5;
$white-smoke-darken: #f3f3f3;
$grey-whisper: #ebeaea;
$grey-gainsboro: #e2e2e2;
$grey-very-light: #cdcdcd;
$grey-ghost: #c8c7cb;
$grey-silver: #bfbfbf;
$grey-heather: #b8babb;
$grey-nobel: #999999;
$grey-dim: #686868;
$blackcurrant: #33033c;
$black-ebony: #2c2e32;

$sidebar-color: $blackcurrant;
$btn-primary: $blue-lochmara;
$btn-primary--hover: #274f7e;
$btn-primary--disabled: #89a0ba;
$btn-secondary: $orange;
$btn-secondary--hover: #f8b702;
$btn-secondary--disabled: #ffde85;
