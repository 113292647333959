@import '../../../../colors';

.imageBox {
  display: flex;
  padding: 7px 0;
  overflow-x: auto;
}

.imageItem {
  margin-right: 15px;
  border-radius: 5px;
  width: 145px;
  height: 95px;
  flex: none;
  position: relative;
  overflow: hidden;

  &:hover {
    .imageActions {
      opacity: 1;
    }
  }
}

.imagePreview {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.imageActions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  opacity: 0;
  transition: all 0.15s;
}

.removeImageBtn {
  position: absolute;
  top: 7px;
  right: 7px;
  background: url('../../../../assets/img/x-white.svg') no-repeat 0 0;
  background-size: contain;
  width: 9px;
  height: 9px;
  padding: 0;
  border: none;
}
