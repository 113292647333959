.service-post {
  .ic-expanded-header {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    word-break: break-word;

    & > div {
      margin-left: 10px;
      font-size: 13px;
      font-weight: 700;
    }
  }

  .ic-expanded-header-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
  }

  .icard-content {
    margin: 0 15px;
    word-break: break-word;
    font-size: 15px;
  }

  .btn-service {
    line-height: 1;
    font-size: 14px;
    padding: 3px 6px;
    margin: 0;
    color: #000;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    border: none;
    background: rgb(246, 205, 78);
    border-radius: 6px;
    outline: none;
    text-indent: 0;

    &:hover {
      text-decoration: none;
      background: rgb(255, 211, 84);
      color: #000;
    }
  }

  .ic-expanded-left {
    max-width: 850px;
  }
  &.ic-expanded {
    .icard-description {
      padding-bottom: 17px;
      padding-right: 0;

      & > p {
        text-indent: 20px;

        & + p {
          margin: 10px 0 0;
        }
      }
    }

    .icard-content-title {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 10px;
      font-weight: 700;
      padding-bottom: 11px;
    }
  }
}
