@import '../../../../colors';

.item {
  display: flex;
}

.label {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4;
  color: $blue-lochmara;
  margin-right: 15px;
  flex-shrink: 0;
  padding: 14px 0;
}

.content {
  width: 100%;
}

.btnBox {
  flex-shrink: 0;
  margin-left: 15px;
  padding: 11px 0;
}
