.all-ic-comments {
  padding: 10px 0;
  max-height: 600px;
  min-height: 55px;
  overflow-y: auto;
}
.show-comments-btn {
  border: none;
  outline: none;
  color: #999;
  padding: 0;
  font-size: 12px;
  text-align: left;
  transition: all 0.2s ease;
  background: transparent;
  margin: 10px 15px 0;

  &:hover {
    color: #000;
    transition: all 0.2s ease;
  }
}
.Comments {
  padding: 0 !important;
}
