@import '../../../../colors';

.wrapper {
  position: relative;
}

.actionsBox {
  margin-top: 7px;
}

.action {
  background: $yellow-kournikova;
  display: inline-block;
  vertical-align: top;
  padding: 7px 25px 7px 12px;
  border-radius: 5px;
  margin: 0 12px 7px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 400px;
  font-size: 14px;
  line-height: 1.4;
  position: relative;

  button {
    border: none;
    padding: 0;
    background: transparent;
    display: flex;
    width: 9px;
    height: 9px;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -5px;
    outline: none;
    color: #000;
    cursor: pointer;
  }
}

.actionForm {
  position: absolute;
  z-index: 2;
  background: #fff;
  padding: 10px;
  width: 360px;
  border: 1px solid $grey-very-light;
  border-radius: 5px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.07);

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px;
    border-color: transparent;
  }

  &.top {
    bottom: 100%;
    left: -17px;
    margin-bottom: 15px;

    &:before,
    &:after {
      border-bottom-width: 0;
      left: 15px;
    }

    &:before {
      bottom: -11px;
      border-top-color: $grey-very-light;
      z-index: 2;
    }

    &:after {
      bottom: -10px;
      border-top-color: #fff;
      z-index: 3;
    }
  }

  &.left {
    top: -37px;
    right: 100%;
    margin-right: 10px;

    &:before,
    &:after {
      border-right-width: 0;
      top: 37px;
    }

    &:before {
      right: -11px;
      border-left-color: $grey-very-light;
      z-index: 2;
    }

    &:after {
      right: -10px;
      border-left-color: #fff;
      z-index: 3;
    }
  }
}

.formRow {
  display: flex;

  & + .formRow {
    padding-top: 10px;
  }
}

.inputBox {
  width: 230px;
  flex-shrink: 0;
  padding-right: 10px;
  padding-bottom: 0;

  input {
    background: #fff;
    height: 31px;
    font-size: 14px;
  }
}
