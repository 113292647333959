@import '../colors';

.wrapper {
  background: $blue-alice-darken;
  height: 100vh;
  overflow-y: auto;
  padding-top: 60px;
}

.container {
  max-width: 700px;
  margin: 0 auto;
  padding: 55px 10px;
}

.containerFlex {
  max-width: 700px;
  margin: 0 auto;
  padding: 55px 10px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 70px);
  justify-content: center;
}
