.itemBox {
  display: flex;
  align-items: center;
  padding: 0 0 2px;
  position: relative;
  z-index: 10;
}

.avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 10px;
}

.login {
  color: #000;
  margin-right: 10px;
}

.name {
  color: #aaa;
}

.login,
.name {
  font-size: 13px;
  white-space: nowrap;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
}
