@import '../../../../colors';

.footer {
  background: $white-smoke-darken;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 5px 5px;
}

.btn {
  border: none;
  border-radius: 5px;
  padding: 6px 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4;
  background: $btn-primary;
  color: #fff;
  min-width: 110px;

  &:hover {
    background: $btn-primary--hover;
  }

  &:disabled {
    background: $btn-primary--disabled;
  }
}
