@import '../../../../colors';

.mainScreenWrapper {
  position: relative;
  outline: none;
}

.modalContent {
  padding: 0 20px 10px;

  hr {
    margin: 0 -20px;
    border-color: $grey-gainsboro;
  }
}

.postTitleBox {
  padding: 20px 0 10px;
}

.postTitle {
  display: block;
  width: 100%;
  border: 1px solid $blue-pattens;
  border-radius: 5px;
  padding: 10px 14px;
  font-size: 14px;
  line-height: 1.4;
  outline: none;
  resize: none;

  &:focus {
    border-color: $blue-lochmara;
  }
}

:global(.text-editor) .postDescription {
  font-size: 14px;
  line-height: 1.4;

  :global(.public-DraftEditorPlaceholder-root) {
    top: 11px;
    left: 16px;
  }

  :global(.DraftEditor-editorContainer) {
    & > div {
      height: 110px;
      overflow-y: auto;
      border: 1px solid $blue-pattens;
      border-radius: 5px;
      padding: 10px 15px;

      &:focus {
        border-color: $blue-lochmara;
      }
    }
  }
}

.textStylingInfo {
  min-height: 20px;
  padding: 2px 0;
  display: flex;
  justify-content: flex-end;
}
