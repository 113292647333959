.work-space-btn-name {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.71);
}

.work-space-btn-name,
.work-space-btn-creator,
.work-space-btn-title {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.work-space {
  cursor: pointer;
  padding: 8px 18px;
  display: flex;
  border-bottom: 2px solid rgba(#f3f3f3, 0.1);

  &:hover {
    background: #1e0124;

    .work-space-btn-name {
      color: #fff;
    }
  }
  &.active {
    background: #6c4b73;

    .work-space-btn-name {
      font-weight: 600;
      color: #fff;
    }
  }
}

.work-space-btn-info {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  font-size: 13px;
  margin-left: 10px;
  min-width: 1px;

  .card-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 1px;
  }

  .additional-info {
  }

  .time {
    font-size: 10px;
    text-align: right;
  }

  .notifications-box {
    margin-top: 10px;
    text-align: right;
  }

  .amount {
    display: inline-block;
    min-width: 22px;
    height: 22px;
    background: #c5dbeb;
    border-radius: 50%;
    color: #113044;
    text-align: center;
    padding: 2px 3px;
  }
}

.work-space-logo {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
  flex: none;
}
.work-spaces-all-icon {
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.03em;
  position: relative;
  background: #ffc100;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  width: 28px;
  height: 28px;
  color: #000;
  text-transform: uppercase;
  cursor: pointer;
}
