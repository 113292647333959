@import '../../../../colors';

.thread-tab-header {
  display: flex;
  position: relative;
  margin-bottom: -1px;
  padding: 5px 5px 0;
  border-top: 1px solid $grey-whisper;

  .share-post-btn-box {
    padding: 10px 10px 0 10px;
  }

  .share-post-btn {
    display: block;
    border: none;
    position: relative;
    background: transparent;
    padding: 0;
    width: 15px;
    height: 18px;
    outline: none;
    flex: none;

    &:before {
      content: '+';
      position: absolute;
      top: -4px;
      left: 0;
      width: 100%;
      text-align: center;
      color: #999999;
      font-size: 24px;
      line-height: 1;
    }

    &:hover {
      opacity: 0.85;
    }
  }
}

.thread-tab-nav-wrap {
  min-width: 1px;
  //display: flex;
  //overflow-x: auto;
  //
  //&::-webkit-scrollbar {
  //  width: 0;
  //  height: 0;
  //  display: none;
  //}
}

.thread-tab-nav {
  display: flex;
  flex-wrap: nowrap;
  min-width: 1px;

  &.wide {
    & > li {
      flex-basis: 250px;
    }
  }

  & > li {
    padding-right: 0;
    position: relative;
    max-width: 250px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border: 1px solid transparent;
    overflow: hidden;

    & + li {
      margin-left: -2px;
    }

    & > a {
      display: block;
      padding: 5px 0 5px 7px;
      color: #999999;
      position: relative;

      &:focus {
        outline: none;
      }
      &:hover {
        text-decoration: none;
      }
    }

    &:after {
      content: '';
      width: 1px;
      height: 18px;
      background: #a8a8a8;
      position: absolute;
      top: 11px;
      right: 0;
    }

    &.active {
      border-color: #afcae0;
      border-bottom-color: #fff;
      background: #fff;

      & > a {
        color: #000;
        cursor: default;
      }

      &:after {
        display: none;
      }
    }

    &:nth-of-type(1) {
      & > a {
        padding-left: 15px;
      }
    }
  }
}

.thread-nav-item {
  display: flex;
  align-items: center;

  img {
    flex: none;
    width: 28px;
    height: 28px;
    margin-right: 10px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.thread-group-name-wrap {
  padding-right: 12px;
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 14px;
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 30%,
      rgb(255, 255, 255) 100%
    );
    background: -ms-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 30%,
      rgb(255, 255, 255) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 30%,
      rgb(255, 255, 255) 100%
    );
  }
}

.thread-group-name {
  white-space: nowrap;
  font-size: 13px;
}

.thread-tab-content {
  border: 1px solid #afcae0;
  background: #fff;
  border-radius: 0 0 4px 4px;
  margin: 0 5px 5px;
}
