.box {
  display: flex;
  align-items: center;
  line-height: 1;

  & > button,
  & > div {
    margin-right: 25px;
  }
}

.attachFileBtn,
.attachImageBtn,
.actionBtn {
  display: block;
  width: 18px;
  height: 18px;
  padding: 0;
  border: none;
  color: #000;
  background-color: transparent;

  &:hover {
    opacity: 0.85;
  }
}

.attachFileBtn {
  background: url('../../../../assets/img/addFile.svg') no-repeat;
  background-size: contain;
}

.attachImageBtn {
  background: url('../../../../assets/img/addImages.svg') no-repeat;
  background-size: contain;
}
