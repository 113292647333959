.App {
  display: block;
  background: #f3f3f3;
}

.main-content-wrapper {
  display: flex;
  max-width: 1300px;
  margin: 0 auto;
}

.main-context {
  flex-grow: 1;
  position: relative;
  height: 100vh;
  overflow: hidden;
}
