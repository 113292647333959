@import '../../../colors';

:global(.IndexCard:not(.expanded)) {
  .cardItem {
    display: none;
  }
}

.cardItem {
  margin: 0 15px 15px;
  padding-bottom: 1px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: default;
  position: relative;

  &.inModal {
    margin: 0;
  }

  &:hover {
    .headerActionsBox {
      opacity: 1;
    }
  }
}

.headerActionsBox {
  position: absolute;
  right: 15px;
  top: 12px;
  z-index: 1;
  display: flex;
  opacity: 0;
}

.header {
  display: flex;
  align-items: center;
  padding: 12px 15px 15px;
  word-break: break-word;
  font-size: 13px;
}

.sharedHeader {
  padding: 12px 0 15px;
}

.avatarBox {
  padding-right: 10px;
  flex-shrink: 0;
}

.avatar {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.headerPostInfo {
  width: 100%;
}

.userName {
  font-weight: bold;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.date {
}

.editedDate {
  font-size: 12px;
  color: $grey-nobel;
}

.contentBox {
  padding: 0 15px 5px;
  word-break: break-word;
}

.title {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 10px;
  font-weight: bold;
}

.imgAndDescBox {
  display: flex;
}

.description {
  flex-grow: 1;
  padding-bottom: 10px;
  padding-right: 70px;
  word-break: break-word;
  font-size: 15px;

  p {
    white-space: pre-wrap;
  }
}

.hashTagsBox {
  padding-bottom: 10px;
  font-size: 15px;

  & > span {
    color: rgb(56, 104, 160);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.filesBox {
  padding-bottom: 5px;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
}

.fileItem {
  display: flex;
  align-items: center;
  margin: 0 15px 5px 0;
}

.downloadIcon {
  background: url('../../../assets/img/downloadFile.svg') no-repeat;
  background-size: contain;
  width: 17px;
  height: 17px;
  margin-right: 7px;
}

.fileName {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
  font-size: 12px;
}

.footer {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: 0 15px;
  //padding: 3px 15px;
  border-top: 1px solid $grey-whisper;
}

.footerCol {
  display: flex;
  flex-wrap: wrap;
}

.starsBox {
  width: 120px;
  flex: none;
  padding: 5px 0;
  min-height: 25px;
  margin-right: 30px;

  label {
    margin-right: 2px;
  }
  :global(.svg-inline--fa.fa-w-18) {
    width: 14px;
    height: 14px;
  }
}

.actionBox {
  padding: 3px 0;
}

.isContentShared {
  background: #f6f6f6;
  padding: 0 15px 3px;
  border-radius: 10px;
  margin: 0 15px 15px;
  word-break: break-word;

  .avatar,
  .userName {
    cursor: default;
  }
}
