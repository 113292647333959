@import '../../../colors';

.box {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-right: 10px;
}

.btn {
  color: $grey-gainsboro;
  background: transparent;
  padding: 0;
  margin: 7px 10px 7px 0;
  border: none;

  &:hover {
    color: $blue-lochmara;
  }
}

.allReactionsBox {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}

.reactionList {
  padding: 3px 0;
}
