.wrapper {
  text-align: center;
  padding: 10px 16px;
  background: #fff;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  button {
    background: transparent;
    border: none;
  }
}
