@import '../../../colors';

.add-workspace-btn {
  background: url('../../../assets/img/plus-dark.svg') no-repeat center center;
  background-size: 12px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: none;
  padding: 0;
  outline: none;
  position: absolute;
  top: 11px;
  right: 8px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.new-group-modal-wrapper {
  text-align: center;
  padding: 15px;

  h2 {
    margin: 0 0 22px;
    font-size: 24px;
    font-weight: bold;
  }

  .new-group-description {
    margin: 0 0 20px;
  }

  .new-group-name-btn {
    border: none;
    color: #fff;
    border-radius: 5px;
    background: $btn-primary;
    width: 96px;
    height: 26px;
    font-size: 14px;
    margin: 50px auto 14px auto;
    outline: none;

    &:hover {
      background: $btn-primary--hover;
    }
    &:disabled {
      background: $btn-primary--disabled;
    }
  }
  .new-group-name-input {
    background: rgb(243, 243, 243);
    text-align: center;
    border: none;
    padding: 10px;
    width: 350px;
    display: block;
    margin: auto;
    color: #000;
    border-radius: 30px;
    outline: none;
  }
}
