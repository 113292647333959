@import '../../../../colors';

.wrapper {
  position: relative;
}

.contactsWrapper {
  padding-top: 7px;
  min-height: 48px;

  :global(.react-autosuggest__container) {
    display: inline-block;
    vertical-align: top;
    position: relative;
  }

  :global(.react-autosuggest__suggestions-container) {
    position: absolute;
    z-index: 5;
    top: 35px;
    left: 0;
    max-height: 260px;
    overflow-y: auto;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    background: #fff;
    width: 260px;

    & > ul {
      padding: 10px 0;
      list-style: none;
    }
  }

  :global(.react-autosuggest__suggestion--highlighted) {
    background: $blue-alice;
  }
}

.tagsWrapper {
  cursor: pointer;
}

.tagsInput {
  background: transparent;
  border: 0;
  color: #000;
  font-size: 14px;
  line-height: 1.4;
  outline: none;
  padding: 7px 5px;
  margin-bottom: 7px;
  width: 100px;
  cursor: pointer;
}

.contactItem {
  display: inline-flex;
  align-items: center;
  padding: 2px 5px;
  background: $blue-solitude;
  border-radius: 5px;
  margin: 0 12px 7px 0;
  position: relative;
}

.contactAvatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
  margin-right: 5px;
}

.contactName {
  font-size: 14px;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 165px;
  color: $blue-lochmara;
}

.contactRemoveBtn {
  border: none;
  cursor: pointer;
  background: url('../../../../assets/img/x-grey.svg') no-repeat 0 0;
  background-size: contain;
  width: 9px;
  height: 9px;
  margin-left: 8px;
  margin-right: 2px;
  padding: 0;
}

.suggestionItem {
  display: flex;
  align-items: center;
  position: relative;
  padding: 5px 30px 5px 10px;
  margin-bottom: 1px;

  &:hover {
    background: $blue-alice;
  }

  &.selected {
    background: $blue-alice;

    .itemName {
      color: $blue-lochmara;
    }
  }
}

.suggestionAvatar {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 50%;
}

.suggestionName {
  font-size: 14px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
}
