.Header {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  height: 50px;
}

.Header > div {
  display: flex;
  align-items: center;
}

.header-col-l {
  display: flex;
  align-items: center;
}

.header-search {
  position: relative;
}
.header-search-input {
  background: #fff;
  position: relative;
  transition: all 0.2s ease;
}

.search-img {
  position: absolute;

  &.search-img-find {
    background: url('../../assets/img/search.png');
    background-size: contain;
    width: 16px;
    height: 16px;
    top: 4px;
    right: 7px;
  }
  &.search-img-clear {
    width: 10px;
    height: 10px;
    background: url('../../assets/img/x-small.png') no-repeat;
    top: 7px;
    right: 7px;
    cursor: pointer;
  }
}

.change-card-view {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0 30px 0 0;
  display: flex;
  cursor: pointer;

  & > img {
    width: 18px;
  }
}

.header-search {
  width: 300px;
  transition: all 0.5s ease;
}

.header-search.expanded {
  transition: all 0.5s ease;
  width: 450px;
}

.header-search-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 450px;
}
