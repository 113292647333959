@import '../../../../colors';

.fileBox {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 7px;
}

.fileItem {
  display: flex;
  align-items: center;
  padding: 7px 12px;
  background: $white-smoke;
  border-radius: 5px;
  margin: 7px 12px 0 0;
}

.fileName {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 160px;
  font-size: 14px;
  line-height: 1.4;
}

.removeFileBtn {
  background: url('../../../../assets/img/x-grey.svg') no-repeat 0 0;
  background-size: contain;
  width: 9px;
  height: 9px;
  border: none;
  margin-left: 8px;
  padding: 0;

  &:hover {
    opacity: 0.85;
  }
}
