@import '../../../../colors';

.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.btn {
  font-size: 12px;
  border: none;
  background: $orange;
  color: #000;
  border-radius: 5px;
  display: inline-block;
  vertical-align: top;
  padding: 2px 5px;
  margin: 2px 5px 2px 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: none;
    color: #000;
    opacity: 0.85;
  }
}
