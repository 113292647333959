.dropZoneWrapper {
  outline: none;
  width: 66px;
  height: 66px;
  margin: 0 auto 25px;
}

.dropZoneHover {
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #fff;
  font-size: 12px;
  line-height: 1.2;
  font-weight: bold;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 0.15s;
  border-radius: 50%;
}

.dropZoneBox {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;

  &:hover {
    .dropZoneHover {
      opacity: 1;
    }
  }
}

.avatar {
  display: block;
  height: 100%;
  margin: 0 auto;
}

.cropperBox {
  width: 345px;
  height: 245px;
  margin: 0 auto 25px;
}
