@import '../../../colors';

:global(.IndexCard.expanded) {
  .cardItem {
    display: none;
  }
}

.cardItem {
  margin: 0 15px 15px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: default;
  position: relative;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 100px 15px 15px;
}

.avatar {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  cursor: pointer;
}

.title,
.userName {
  margin-left: 10px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userName {
  color: $grey-nobel;
}

.time {
  font-size: 11px;
  position: absolute;
  right: 15px;
  top: 12px;
}

.contentBox {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  word-break: break-word;
}

.description {
  font-size: 14px;
  padding-bottom: 15px;
  margin-bottom: 3px;
  max-height: 55px;
  line-height: 18px;
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgb(255, 255, 255) 90%,
      rgb(255, 255, 255) 100%
    );
  }
}

.footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.infoBlock {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 20px;
  background: $white-smoke;
  border-radius: 0 6px;
  color: $grey-heather;
  font-size: 14px;

  & > div {
    display: flex;
    align-items: center;
    padding: 5px 10px;

    img {
      margin-right: 8px;
    }
  }
}

.actionBlock {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 4px;
}

.stars {
  label {
    margin-right: 4px;
  }

  :global(.svg-inline--fa.fa-w-18) {
    width: 14px;
  }
}
