.box {
  background: #fff;
  padding: 35px 20px 50px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
}

.content {
  margin: 0 auto;
}

.form {
  max-width: 330px;
  margin: 0 auto;
}

.title {
  font-size: 30px;
  text-align: center;
  margin: 0 0 25px;
}

.subTitle {
  font-size: 16px;
  line-height: 1.2;
  margin: 0 0 10px;
  text-align: center;
}

.btnBox {
  padding-top: 15px;
}

.btnIcon {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 10px;
  border-color: transparent transparent transparent #000;
  margin: 0 0 3px 5px;
}
