.dropdown-post {
  position: relative;
  margin: 10px 0 0;
  padding-right: 30px;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;

  &:hover {
    background: #f3f3f3;
  }

  &.disabled {
    &:hover {
      background: none;
    }
  }

  .input-checkbox {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 5;
    cursor: default;
  }

  .avatar {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    flex: none;
    border-radius: 50%;
    object-fit: cover;
  }

  .name {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .checkbox-view {
    position: absolute;
    right: 10px;
    top: 8px;
    width: 14px;
    margin: 0;
  }
}
