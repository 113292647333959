@import '../../colors';

.sidebar {
  background: #fff;
  width: 280px;
  height: 100vh;
  flex: none;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

.work-spaces-wrapper {
  overflow: hidden;
  height: 100%;
}

.work-spaces-search {
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 7px 8px;
  cursor: pointer;
}

.work-spaces-overlay {
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 0 5px;
  width: 100%;
  height: calc(100vh - 86px);
  overflow-y: auto;
  background: $sidebar-color;
  position: relative;
  z-index: 2;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.work-spaces::-webkit-scrollbar {
  display: none;
}

.work-spaces-dropbox {
  background: url('../../assets/img/mockImg/dropbox.png');
  background-size: contain;
}

.channels-search {
  box-sizing: border-box;
  background: #e6e6e6;
  padding: 3px 25px 3px 14px;
  font-size: 14px;
  width: 100%;
  border: none;
  border-radius: 4px;
  outline: none;
  color: #000;
  height: 25px;
}

.channels {
  background: #fff;
  padding: 10px;
  display: inline-block;
  width: 210px;
}

.sidebar-search {
  width: 100%;
  position: relative;
  line-height: 1.3;

  //.channels-search {
  //font-size: 14px;
  //}

  .clear-btn {
    position: absolute;
    top: 7px;
    right: 7px;
    border: none;
    outline: none;
    width: 10px;
    height: 10px;
    background: url('../../assets/img/x-small.png') no-repeat;
    padding: 0;
  }
  .loader {
    position: absolute;
    top: 4px;
    right: 22px;
  }
}

.sidebar-tab-nav {
  display: flex;
  height: 40px;
  margin: 0 7px;

  &:before,
  &:after {
    display: none;
  }

  li {
    width: 50%;

    & > a {
      display: block;
      color: #000;
      padding: 0;
      font-size: 12px;
      height: 40px;
      cursor: pointer;
      border-radius: 4px 4px 0 0;
      position: relative;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.12) 100%
        );
        text-decoration: none;
      }

      &:focus {
        outline: none;
      }

      .icon-groups {
        background: url('../../assets/img/tabGroups.svg') no-repeat;
        width: 20px;
        height: 15px;
      }

      .icon-users {
        background: url('../../assets/img/tabUsers.svg') no-repeat;
        width: 18px;
        height: 15px;
      }

      .icon-groups,
      .icon-users {
        margin-right: 8px;
      }
    }

    &.active {
      & > a {
        background: $sidebar-color;
        color: #fff;
        box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);

        .icon-groups {
          background-image: url('../../assets/img/tabGroupsActive.svg');
        }

        .icon-users {
          background-image: url('../../assets/img/tabUsersActive.svg');
        }
      }

      .add-workspace-btn {
        background-image: url('../../assets/img/plus-white.svg');

        &:hover {
          background-color: rgba(255, 235, 173, 0.08);
        }
      }
    }
  }

  .tab-nav-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px 10px;

    &.tab-nav-content-groups {
      padding-right: 40px;
    }
  }
}
