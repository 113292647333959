@import '../../../../colors';

.GroupAddMembers {
  h2 {
    margin: 0 0 18px 0;
  }

  .not-found {
    height: 255px;
    text-align: center;
  }

  .new-group-search {
    width: 275px;
    background: rgb(243, 243, 243);
    color: #000;
    padding: 5px 20px;
    border: none;
    outline: none;
    font-size: 13px;
    border-radius: 6px;
    margin-bottom: 8px;
  }

  .add-member-buttons {
    button {
      border: none;
      color: #fff;
      border-radius: 5px;
      background: $btn-primary;
      width: 96px;
      height: 26px;
      font-size: 13px;
      margin: 20px 10px 14px 0;
      outline: none;

      &:hover {
        background: $btn-primary--hover;
      }
      &:disabled {
        background: $btn-primary--disabled;
      }
    }
  }

  .add-group-dropdown {
    width: 275px;
    height: 255px;
    text-align: left;
    background: #fff;
    padding: 5px 0;
    margin: auto;
    max-height: 225px;
    overflow: scroll;
  }
}

.add-group-checkbox {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;

  .checkbox-item {
    width: 74px;
    position: relative;
  }

  .checkbox-view {
    height: 22px;
    border-radius: 22px;
    background: $blue-lochmara;
    color: #fff;
    font-size: 10px;
    line-height: 1;
    padding: 5px 6px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
    transition: all 0.2s;
    position: relative;

    .text-unchecked {
      display: block;
      text-align: right;
    }

    .text-checked {
      display: none;
      text-align: left;
    }

    .slider {
      width: 30px;
      height: 18px;
      border-radius: 18px;
      background: #fff;
      text-align: center;
      box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 2px;
      left: 2px;
      transition: all 0.2s;

      span {
        height: 10px;
        width: 1px;
        background: #d4e3ec;
        display: inline-block;
        vertical-align: top;
        margin: 4px 2px 4px 1px;
      }
    }
  }

  .checkbox {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    cursor: pointer;

    &:checked {
      & + .checkbox-view {
        .text-unchecked {
          display: none;
        }

        .text-checked {
          display: block;
        }

        .slider {
          left: 42px;
        }
      }
    }
  }
}
