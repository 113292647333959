@import '../../../../colors';

.add-new-comment-wrapper {
  padding: 10px 15px 4px;
}
.add-new-comment {
  display: flex;
  align-items: flex-start;
  position: relative;

  .user-img {
    width: 28px;
    height: 28px;
    margin-right: 10px;
    border-radius: 50%;
    object-fit: cover;
    flex-shrink: 0;
  }
  .btn-box {
    padding: 3px 5px 0;
    width: 30px;
  }
  .btn-send-comment {
    background: transparent;
    width: 20px;
    height: 20px;
    border: none;
    outline: none;
    padding: 0;
    line-height: 1;
    flex-shrink: 0;
    color: $blue-lochmara;

    &:disabled {
      color: $grey-gainsboro;
    }
  }
  .thread-avatar {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    object-fit: cover;
    flex-shrink: 0;
  }
}

.add-comment-editor-wrapper {
  width: 100%;
}

.add-comment-editor {
  min-height: 28px;
  max-height: 200px;
  overflow-y: auto;
  font-size: 14px;
  background: #fff;
  border: 1px solid $grey-silver;
  border-radius: 5px;
  padding: 3px 10px;

  .public-DraftEditorPlaceholder-root {
    color: $grey-nobel;
  }
}

.add-comment-additional-info {
  display: flex;
  justify-content: space-between;
  padding: 3px 30px 0 40px;
  min-height: 18px;
}

.add-comment-error-box {
  position: relative;
  font-size: 12px;
  padding-right: 15px;
}
