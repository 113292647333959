@import '../../../colors';

.box {
  padding-bottom: 10px;
  position: relative;
}

.field {
  display: block;
  width: 100%;
  border: 1px solid $blue-pattens;
  border-radius: 5px;
  font-size: 15px;
  padding: 5px 15px;
  height: 36px;
  outline: none;
  background: $blue-alice;

  &:focus {
    border-color: $blue-lochmara;
  }

  &:disabled {
    background: $white-smoke;
  }

  &.error {
    border-color: #f00;
  }

  &.showChildrenOnFocus:focus {
    & + div {
      opacity: 1;
    }
  }
}
