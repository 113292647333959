@import '../../../colors';

.wrapper {
  background: rgba(255, 255, 255, 1);
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba($blue-alice-darken, 1) 50%,
    rgba($blue-alice-darken, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba($blue-alice-darken, 1) 50%,
    rgba($blue-alice-darken, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba($blue-alice-darken, 1) 50%,
    rgba($blue-alice-darken, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba($blue-alice-darken, 1) 50%,
    rgba($blue-alice-darken, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba($blue-alice-darken, 1) 50%,
    rgba($blue-alice-darken, 1) 100%
  );
  height: 100vh;
  overflow-y: auto;
  padding-top: 60px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1020px;
  margin: 0 auto;
  min-height: 100%;
  align-items: center;
}

.containerCol {
  width: 50%;
}

.content {
  padding: 40px;
  min-height: 420px;
}

.title {
  font-size: 36px;
  line-height: 1.2;
  margin: 0 0 30px;
}

.form {
  padding-top: 30px;
  max-width: 300px;
}

.inviteForm {
  padding-top: 0;
}

.subTitle {
  font-size: 24px;
  line-height: 1.3;
  margin: 0 0 30px;
}

.welcomeDescription {
  font-size: 17px;
  line-height: 1.3;
  margin: 0 15px 30px 0;
}

.welcomeBtnBox {
  max-width: 300px;
  padding-top: 10px;
}

.submitBtnBox {
  padding-top: 20px;
}

.imageBox {
  padding: 40px 10px 40px 60px;

  img {
    max-width: 100%;
  }
}

.skipInvitationBox {
  padding-top: 40px;
  text-align: center;
  font-size: 20px;
  color: #404040;
  max-width: 300px;

  a {
    color: #5491d3;
    text-decoration: none;

    &:hover {
      color: #3768a0;
    }
  }
}

@media screen and (max-width: 767px) {
  .containerCol {
    width: 100%;
  }

  .imageBox {
    display: none;
  }
}
