@import '../../../colors';

.wrapper {
  height: 100vh;
  overflow-y: auto;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  background: #fff;
  min-height: 100%;
}

.header {
  background: $blackcurrant;
  color: #fff;
  font-size: 19px;
  line-height: 1.3;
  text-align: center;
  padding: 0 25px;
  overflow: hidden;
}

.headerTitle {
  max-width: 240px;
  margin: 0 auto;
  padding: 14px 0;
  position: relative;
  color: $orange;
  font-size: 27px;
  font-weight: bold;

  &:after {
    content: '';
    background: url('../../../assets/img/congrats.png') no-repeat;
    width: 81px;
    height: 62px;
    position: absolute;
    bottom: 0;
    right: -100px;
  }
}

.description {
  font-size: 16px;
  padding: 50px 25px;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.formBox {
  max-width: 350px;
  margin: 0 auto;
  padding: 0 15px 40px;
}

.fieldsList {
  padding: 10px 0 0;

  label {
    font-size: 14px;
    display: block;
    padding: 0 0 3px;
  }
}

.fieldBox {
  padding-bottom: 12px;
  position: relative;

  input {
    display: block;
    width: 100%;
    border: 1px solid $blue-pattens;
    background: $blue-alice;
    border-radius: 5px;
    font-size: 15px;
    padding: 5px 15px;
    height: 36px;
    outline: none;

    &:focus {
      border-color: $blue-lochmara;

      & + .passwordInfo {
        opacity: 1;
      }
    }

    &:disabled {
      background: $white-smoke;
    }

    &.errorField {
      border-color: #f00;
    }
  }
}

.passwordInfo {
  position: absolute;
  top: -5px;
  left: 100%;
  padding-left: 15px;
  font-size: 13px;
  line-height: 1.2;
  width: 175px;
  color: $grey-dim;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s;
}

.btnAcceptBox {
  text-align: center;
  padding-top: 20px;
}

.btnAccept {
  display: block;
  width: 100%;
  border-radius: 5px;
  padding: 7px 25px;
  border: none;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.4;
  text-transform: uppercase;
  background: $btn-secondary;

  &:hover {
    background: $btn-secondary--hover;
  }
  &:disabled {
    background: $btn-secondary--disabled;
  }
}
