@import '../../../../colors';

.comment-enter {
  opacity: 0;
}

.comment-enter.comment-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease;
}
.comment-leave {
  opacity: 1;
}

.comment-leave.comment-leave-active {
  opacity: 0;
}

.Comments {
  .comment {
    &-with-avatar {
      padding-top: 2px;
      padding-bottom: 2px;
    }

    &-time {
      font-size: 10px;
      line-height: 1.4;
      padding: 3px 0;
      color: $grey-nobel;
      opacity: 0;
      display: block;
      text-align: center;
    }

    &-aside {
      flex-shrink: 0;
      box-sizing: border-box;
      width: 53px;
    }

    &-content {
      flex-grow: 1;
    }

    &:hover {
      .comment-time {
        opacity: 1;
      }
    }
  }

  .comment-body {
    font-size: 14px;
    word-break: break-word;
    min-height: 20px;

    p {
      white-space: pre-wrap;

      &:nth-last-of-type(1) {
        display: inline;
      }
    }
  }
  .label-edited {
    font-size: 10px;
    color: $grey-nobel;
  }
  .user-info {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    font-size: 12px;
  }
  .c-avatar {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 15px;
    margin-top: 6px;
  }
  .c-name {
    padding-right: 10px;
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 600;
  }
  .c-time {
    color: $grey-nobel;
  }
}
