.wrapper {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  z-index: 100;
}

.box {
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 2px dashed #000;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.description {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  padding-top: 30px;
}
