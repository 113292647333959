@import '../../../../colors';

.box {
  position: absolute;
  z-index: 5;
  top: 45px;
  right: 0;
  background: #fff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  padding: 10px 0;
  width: 260px;
}

.title {
  font-size: 16px;
  padding: 0 10px 10px;
}

.inputBox {
  padding: 0 10px 10px;
}

.input {
  display: block;
  width: 100%;
  border: 1px solid transparent;
  background: $white-smoke-darken;
  border-radius: 5px;
  padding: 5px 12px;
  font-size: 14px;
  line-height: 1;
  height: 30px;
  outline: none;

  &:focus {
    border-color: $grey-gainsboro;
  }
}

.contactsList {
  max-height: 245px;
  overflow-y: auto;
}

.itemBox {
  display: flex;
  align-items: center;
  position: relative;
  padding: 5px 30px 5px 10px;
  margin-bottom: 1px;

  &:hover {
    background: $blue-alice;
  }

  &.selected {
    background: $blue-alice;

    .itemName {
      color: $blue-lochmara;
    }
  }
}

.checkbox {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.itemAvatar {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 50%;
}

.itemName {
  font-size: 14px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
}

.checkmark {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -8px;
  color: $blue-lochmara;
  line-height: 1;
}
