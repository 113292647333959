@import '../../colors';

.box {
  background: #fff;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
}

.content {
  padding: 35px 20px;
  margin: 0 auto;
}

.form {
  max-width: 330px;
  margin: 0 auto;

  :global(.error) {
    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.title {
  font-size: 30px;
  text-align: center;
  margin: 0 0 25px;
}

.subTitle {
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
}

.description {
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
  margin-top: 40px;
}

.btnBox {
  padding-top: 12px;
}

.sendingInfoBox {
  padding: 35px 20px;
  background: url('../../assets/img/emailSent.png') no-repeat center -20px;
  min-height: 460px;
}

.loginLinkBox {
  font-size: 16px;
  text-align: center;
  margin-top: 60px;

  a {
    color: $orange;
    font-weight: 600;
  }
}
