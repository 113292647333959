.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-left: 15px;
}

.avatar {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

.listBox {
  width: 120px;
  background: #fff;
  position: absolute;
  right: -3px;
  top: 100%;
  margin-top: 6px;
  color: #000;
  border-radius: 4px;
  z-index: 2;
  box-shadow: 0 3px 11px rgba(0, 0, 0, 0.2);
}

.hidden {
  display: none;
}

.list {
  list-style: none;
  padding: 8px 10px 10px;

  & > li {
    & + li {
      margin-top: 5px;
    }
  }

  button {
    border: none;
    font-size: 12px;
    padding: 0;
    background: transparent;
    color: #34383d;

    &:hover {
      color: #d64658;
    }
  }
}
