@import '../../../../colors';

.modalContent {
  padding: 30px 20px;
}

.title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 5px 0 25px;
}

.btnApplyWrapper {
  text-align: center;
  padding-top: 35px;
}

.btnApply {
  font-size: 16px;
  line-height: 1;
  font-weight: bold;
  color: #fff;
  padding: 11px;
  background: $btn-primary;
  min-width: 135px;
  border: none;
  border-radius: 3px;
  outline: none;

  &:hover {
    background: $btn-primary--hover;
  }
  &:disabled {
    background: $btn-primary--disabled;
  }
}

.dropZoneWrapper {
  outline: none;
}

.dropZoneHover {
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 0.15s;
}

.dropZoneBox {
  width: 245px;
  height: 245px;
  margin: 0 auto;
  position: relative;
  cursor: pointer;

  &:hover {
    .dropZoneHover {
      opacity: 1;
    }
  }
}

.avatar {
  display: block;
  height: 100%;
  margin: 0 auto;
}

.cropperBox {
  width: 345px;
  height: 245px;
  margin: 0 auto;
}
