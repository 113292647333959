@import '../../../colors';

.btn {
  display: block;
  width: 100%;
  border-radius: 5px;
  border: none;
  line-height: 1.4;
  text-align: center;
}

.yellow {
  color: #000;
  background: $yellow-selective;

  &:hover {
    background: darken($yellow-selective, 1%);
  }

  &:disabled {
    background: $yellow-selective;
    opacity: 0.8;
  }
}

.blue {
  color: #fff;
  background: $btn-primary;

  &:hover {
    background: $btn-primary--hover;
  }

  &:disabled {
    background: $btn-primary--disabled;
  }
}

.lg {
  font-size: 16px;
  font-weight: bold;
  padding: 7px 15px;
  text-transform: uppercase;
}

.md {
  font-size: 14px;
  font-weight: bold;
  padding: 6px 10px;
}
