@import '../../colors';

.overlay {
  background: transparent;
  width: 370px;
  left: 280px;
  margin: 0 auto;
  bottom: auto;
  z-index: 100;
  box-shadow: 0 3px 11px rgba(0, 0, 0, 0.35);
  border-radius: 0 0 4px 4px;
}

.content {
  position: relative;
}

.box {
  background: $blackcurrant;
  display: flex;
  align-items: center;
  padding: 8px 27px;

  button {
    font-size: 10px;
    text-transform: uppercase;
    background: $btn-secondary;
    white-space: nowrap;
    border-radius: 3px;
    padding: 7px 18px;
    margin-left: 10px;
    border: none;

    &:hover {
      background: $btn-secondary--hover;
    }

    &:disabled {
      background: $btn-secondary--disabled;
    }
  }
}

.avatar {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 10px;
}

.name {
  color: #fff;
  font-size: 18px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
