@import '../../../../colors';

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border: none;
  border-radius: 5px;
  padding: 0 0 1px;
  line-height: 1;
  background: rgba($grey-very-light, 0.4);
  color: $grey-very-light;

  &:hover {
    color: $grey-heather;
  }

  &:disabled {
    color: $grey-very-light;
  }
}
