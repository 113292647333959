@import '../../../colors';

.modalContent {
  padding: 0 20px 15px;

  hr {
    margin: 0 -20px;
    border-color: $grey-gainsboro;
  }
}

.shareComment {
  font-size: 14px;
  line-height: 1.4;
  margin-top: 20px;

  :global(.public-DraftEditorPlaceholder-root) {
    top: 11px;
    left: 16px;
  }

  :global(.DraftEditor-editorContainer) {
    & > div {
      height: 90px;
      overflow-y: auto;
      border: 1px solid $blue-pattens;
      border-radius: 5px;
      padding: 10px 15px;

      &:focus {
        border-color: $blue-lochmara;
      }
    }
  }
}

.postView {
  margin-top: 20px;
  pointer-events: none;
}
