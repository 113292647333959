@import '../../../colors';

.box {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    padding: 2px 5px 2px 0;
    position: relative;
  }
}

.btn {
  display: flex;
  align-items: center;
  border: 1px solid $blue-pattens;
  border-radius: 4px;
  background: $white-smoke-darken;
  height: 22px;
  font-size: 12px;
  line-height: 1;
  padding: 2px 4px;

  &:hover {
    background: $blue-solitude;

    & + .reactedUsersBox {
      opacity: 1;
      z-index: 2;
    }
  }

  img {
    height: 16px;
    flex-shrink: 0;
    margin-right: 3px;
  }
}

.reactedUsersBox {
  position: fixed;
  transform: translateX(-50%) translateY(-100%);
  margin-top: -5px;
  margin-left: 18px;
  width: 150px;
  background: #000;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 4px 5px;
  font-size: 13px;
  line-height: 1.4;
  word-break: break-word;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.2s;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -3px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px 5px 0 5px;
    border-color: #000 transparent transparent transparent;
  }
}
