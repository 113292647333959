.bell-img {
  width: 18px;
}

.notifications-counter {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  margin-left: 15px;
}

.n-count {
  background: #f6cd4e;
  position: absolute;
  top: 2px;
  right: 0;
  transform: translateX(50%);
  min-width: 16px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: bold;
  color: #fff;
  font-size: 12px;
  padding: 0 3px;
}
