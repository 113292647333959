@import '../../../colors';

.react-modal-content {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 5px;
  margin-top: 50px;
  margin-bottom: 50px;

  //&.xs {
  //  width: 300px;
  //}
  //&.xs2 {
  //  width: 350px;
  //}
  &.xs3 {
    width: 450px;
  }
  &.sm {
    width: 500px;
  }
  &.md {
    width: 650px;
  }
  &.md2 {
    width: 750px;
  }
  &.md3 {
    width: 850px;
  }
  &.lg {
    width: 1100px;
  }
}

.react-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
}

.react-modal-close-btn {
  position: absolute;
  top: 6px;
  right: 6px;
  background: transparent;
  display: flex;
  border: none;
  padding: 0;
  z-index: 10;
  color: $grey-gainsboro;

  &:hover {
    color: $grey-heather;
  }
}
