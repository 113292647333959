@import '../../../colors';

.modalContent {
  padding: 0 60px;

  hr {
    margin: 0 -60px;
    border-color: $grey-gainsboro;
  }
}

.title {
  font-size: 24px;
  padding: 10px 0;
}

.descriptionRow {
  font-size: 16px;
  padding-top: 25px;
  display: flex;

  & > p {
    width: 50%;

    & + p {
      margin-left: 10px;
    }
  }
}

.fieldsList {
  padding: 10px 0 10px;
}

.fieldsRow {
  display: flex;
}

.fieldBox {
  padding-bottom: 12px;
  width: 50%;

  input {
    display: block;
    width: 100%;
    border: 1px solid $blue-pattens;
    background: $blue-alice;
    border-radius: 5px;
    font-size: 15px;
    padding: 5px 15px;
    height: 32px;
    outline: none;

    &:focus {
      border-color: $blue-lochmara;
    }

    &:disabled {
      background: $white-smoke;
      border-color: $grey-whisper;
    }

    &.errorField {
      border-color: #f00;
    }
  }

  & + .fieldBox {
    margin-left: 10px;
  }
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  background: $white-smoke-darken;
  border-radius: 0 0 5px 5px;
  padding: 17px 60px;
}

.btnInvite {
  background: $btn-secondary;
  border-radius: 5px;
  padding: 4px 25px;
  border: none;
  color: #000;
  font-size: 18px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    background: $btn-secondary--hover;
  }
  &:disabled {
    background: $btn-secondary--disabled;
  }
}
