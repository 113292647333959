.main-page-loader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.error-subscription-box {
  background: #6c4b73;
  padding: 6px;
  line-height: 1.3;
  border-radius: 6px;
  color: #fff;
  position: absolute;
  top: 50px;
  right: 18px;

  .reload-btn {
    display: inline-block;
    color: #fff;
    background: #33033c;
    font-size: 14px;
    padding: 3px 8px;
    border-radius: 6px;
    border: none;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    margin-top: 5px;

    &:hover {
      background: #3d0346;
    }
  }
}
