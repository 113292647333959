@import '../../../colors';

.wrapper {
  position: relative;
  border: 1px solid $blue-pattens;
  overflow: hidden;

  &:global(.focus) {
    border-color: $blue-lochmara;
  }

  :global(.react-tagsinput-tag) {
    background: $blue-solitude;
    display: inline-block;
    vertical-align: top;
    padding: 7px 25px 7px 12px;
    border-radius: 5px;
    margin: 0 12px 7px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 160px;
    font-size: 14px;
    line-height: 1.4;
    position: relative;
  }

  :global(.react-tagsinput-remove) {
    cursor: pointer;
    background: url('../../../assets/img/x-grey.svg') no-repeat 0 0;
    background-size: contain;
    width: 9px;
    height: 9px;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -4px;
  }

  :global(.react-tagsinput-input) {
    background: transparent;
    border: 0;
    color: #000;
    font-size: 14px;
    line-height: 1.4;
    outline: none;
    padding: 7px 5px;
    margin-bottom: 7px;
    width: 100px;
  }
}
