@import '../../colors';

.box {
  background: #fff;
  padding: 35px 20px 30px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 30px;
  text-align: center;
  margin: 0 0 25px;
}

.subTitle {
  font-size: 18px;
  line-height: 1.2;
  margin: 0 auto;
  max-width: 450px;
}

.list {
  max-width: 450px;
  margin: 25px auto 0;
}

.listItem {
  border: none;
  border-top: 1px solid $grey-ghost;
  padding: 10px 0;
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
  background: transparent;
  width: 100%;
  text-align: left;

  &:nth-of-type(1) {
    border-top: none;
  }

  &:hover {
    color: $yellow-selective;
    text-decoration: none;

    .arrowBox {
      color: $yellow-selective;
    }
  }
}

.name {
  font-size: 18px;
  width: 100%;
  word-break: break-word;
}

.arrowBox {
  color: $grey-ghost;
  flex-shrink: 0;
  padding-left: 15px;
}

.emptyList {
  text-align: center;
  font-size: 18px;
  padding: 40px 0 20px;
}

.logoutBox {
  font-size: 16px;
  text-align: center;
  margin-top: 40px;
}

.btnLogout {
  color: $orange;
  font-weight: 600;
  padding: 0;
  border: none;
  background: transparent;
}
